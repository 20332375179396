import React from "react";
import { PropTypes } from "prop-types";

import basicFetch from "../../utils/basicFetch";
import playerLoader from "../../utils/playerLoader";

import style from "./PlayerShadowDom.module.scss";

/**
 * Renders the WebGL player inside a shadowDOM.
 *
 * Usage :
 * ```js
 * <Player2ShadowDom
 *   onPlayerCreated={player => this.onPlayerCreated(player)}
 *   size='normal'
 *   title={activity.title}
 *   appBar
 * />
 * ```
 * @arguments {Component<Props, State>}
 *
 */

class Player2ShadowDom extends React.Component {
  player = null;
  playerContainer = null;
  version = process.env.REACT_APP_PLAYER_2_VERSION
  stage = process.env.REACT_APP_PLAYER_2_ENV

  async componentDidMount() {
    const asset_manifest = await basicFetch(`https://dev.vivedlearning.com/v2/${this.stage}/${this.version}/asset-manifest.json`,{
      method: "get"
    })

    const shadow = this.buildShadow()

    let entrypoints = asset_manifest.entrypoints.map(path => {
      return `https://dev.vivedlearning.com/v2/${this.stage}/${this.version}/${path}`
    })
    await playerLoader(entrypoints, shadow)

    this.startPlayer(shadow)
  }

  buildShadow() {
    let shadowParent = document.getElementById("player-shadow");
    let shadow = shadowParent.attachShadow({ mode: "open" });

    shadow.innerHTML = `
    <link type="text/css" href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <style> .player-container{ display: flex; height: 100%; width: 100%; } </style>
    <div id="player-container-authoring" class="player-container" />
    `;

    this.playerContainer = shadow.getElementById("player-container-authoring");

    shadow.createElement = (...args) => document.createElement(...args);
    shadow.createElementNS = (...args) => document.createElementNS(...args);
    shadow.createTextNode = (...args) => document.createTextNode(...args);

    return shadow
  }

  startPlayer(shadow) {

    let options = {
      ...this.props.playerOptions,
      // playerID: "1acc340f-cf40-44eb-9e1d-5607c1ae4f07",
      // secret: "IKIjmmhBqREJANHtlTmbQ6Y6GJ5kSszxe31Xu43LVQ3fRxz6AuMYc4zNPw8aUi16og1oa"
      playerId: "9edccc93-bce4-40ed-a716-6b58294c8d99",
      secret:
    "IKIjmmhBqREJANHtlTmbQ6Y6GJ5kSszxe31Xu43LVQ3fRxz6AuMYc4zNPw8aUi16og1oa"
    };

    this.player = window.vived_player.mount(
      this.playerContainer,
      options
    );
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }

    if(this.playerContainer)
    {
      window.vived_player.unmount(this.playerContainer);
    }
  }

  render() {
    const classes = [
      style.container,
      this.props.size === "normal" ? style.containerNormal : null,
      this.props.size === "freeplay" ? style.containerFreeplayFullsize : null,
      this.props.size === "authoring" ? style.containerAuthoring : null,
      this.props.size === "embed" ? style.containerEmbed : null,
      this.props.size === "fill" ? style.containerFill : null,
    ].join(" ");
    return <div id="player-shadow" className={classes} />;
  }
}

Player2ShadowDom.defaultProps = {
  appBar: false
};

Player2ShadowDom.propTypes = {
  appBar: PropTypes.bool,
  playerOptions: PropTypes.any,
  size: PropTypes.oneOf(["normal", "freeplay", "authoring", "embed", "fill"]).isRequired,
  title: PropTypes.string
};

export default Player2ShadowDom;
