import React from 'react';
import { Route, Switch } from 'react-router-dom';

import About from './containers/About/About';
import AccountActivityDelete from './containers/AccountActivtyDelete/AccountActivityDelete';
import AccountChannelDelete from './containers/AccountChannelDelete/AccountChannelDelete';
import AccountChannelEdit from './containers/AccountChannelEdit/AccountChannelEdit';
import AccountChannelNew from './containers/AccountChannelNew/AccountChannelNew';
import ActivityConsumption from './containers/ActivityConsumption/ActivityConsumption';
import { ActivityEdit } from './containers/ActivityEdit/ActivityEdit';

import ActivityNew from './containers/ActivityNew/ActivityNew';
import Admin from './containers/Admin/Admin';
import AdminChannel from './containers/AdminChannel/AdminChannel';
import AdminChannelResourcesEdit from './containers/AdminChannelResourcesEdit/AdminChannelResourcesEdit';
import Author from './containers/Author/Author';
import AuthorPlayerV2 from './containers/Author/AuthorPlayerV2';
import Channel from './containers/Channel/Channel';
import ChannelUsers from './containers/ChannelUsers/ChannelUsers';
import Channels from './containers/Channels/Channels';
import CollectionFreeplay from './containers/CollectionFreeplay/CollectionFreeplay'
import Collections from './containers/Collections/Collections';
import Dashboard from './containers/Dashboard/Dashboard';
import Debug from './containers/Debug/Debug';
import Embed from "./containers/Embed/Embed";
import Home from './containers/Home/Home';
import JoinChannels from './containers/JoinChannels/JoinChannels';
import Landing from './containers/Landing/Landing';
import Logout from './containers/Logout/Logout';
import MainWrapper from './MainWrapper';
import PasswordRecovery from './containers/PasswordRecovery/PasswordRecovery';
import Register from './containers/Register/Register';
import RequestChannel from './containers/RequestChannel/RequestChannel';
import SignIn from './containers/SignIn/SignIn';
import SignInRedirect from './containers/SignInRedirect/SignInRedirect';
import Terms from './containers/Terms/Terms';
import VerifyEmail from './containers/VerifyEmail/VerifyEmail';
import { AccountEdit } from './containers/AccountEdit/AccountEdit';
import { ApplicationError } from './containers/ApplicationError/ApplicationError';

const Router = () => (
  <MainWrapper>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/oldhome" component={Home} />

        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/activity/:activityId" component={ActivityConsumption} />
        <Route exact path="/activity/:activityId/edit" component={ActivityEdit} />
        <Route exact path="/activity/:activityId/embed" component={Embed} />
        <Route exact path="/activity/:activityId/delete" component={AccountActivityDelete} />
        
        <Route exact path="/models" component={Collections} />
        <Route exact path="/models/:collectionId" component={CollectionFreeplay} />

        <Route exact path="/channels" component={Channels} />
        <Route exact path="/channel/:channelId/new" component={ActivityNew} />
        <Route exact path="/channel/:channelId/new-v2" component={AuthorPlayerV2} />
        <Route exact path="/channel/:channelId/users" component={ChannelUsers} />
        <Route exact path="/channel/:channelId/new/:collectionId" component={Author} />
        <Route exact path="/channel/:channelId/activity/:activityId" component={ActivityConsumption}/> {/*Deprecated*/}
        <Route exact path="/channel/:channelId/activity/:activityId/edit" component={ActivityEdit}/> {/*Not intended for regular usage*/}
        <Route path="/channel/:channelId" component={Channel} />
        
        <Route exact path="/join" component={JoinChannels} />
        <Route exact path="/content-creators" component={RequestChannel} />

        <Route exact path="/landing" component={Landing} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route exact path="/reset-password" component={PasswordRecovery} />

        <Route exact path="/account/edit" component={AccountEdit} />
        <Route exact path="/account/logout" component={Logout} />
        <Route exact path="/account/channels/new" component={AccountChannelNew} />
        <Route exact path="/account/channels/edit/:channelId" component={AccountChannelEdit} />
        <Route exact path="/account/channels/delete/:id" component={AccountChannelDelete} />

        <Route exact path="/admin" component={Admin} />
        <Route exact path="/admin/channel/:channelId" component={AdminChannel} />
        <Route exact path="/admin/channel/:channelId/edit" component={AdminChannelResourcesEdit} />

        <Route exact path="/embed/:activityId" component={Embed} />

        <Route exact path="/about" component={About} />
        <Route exact path="/terms" component={Terms} /> 

        <Route exact path="/signInRedirect" component={SignInRedirect} />

        <Route path="/debug" component={Debug} />
        <Route exact path="/404" component={ApplicationError} />
        <Route exact path="/application-error" component={ApplicationError} />
        <Route component={ApplicationError} />
      </Switch>
  </MainWrapper>
);

export default Router;
