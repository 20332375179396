import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import TagManager from 'react-gtm-module';

import App from './App';
import './scss/main.scss';
import Config from "./aws-exports";
import { getGtmConfig } from "./utils/gtmConfig";
import * as serviceWorker from './serviceWorker';

Amplify.configure(Config(window.location.pathname));

// Initializes Google Tag Manager
const tagManagerOptions = getGtmConfig();
TagManager.initialize(tagManagerOptions);


ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
