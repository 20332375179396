import React from "react";
import PropTypes from "prop-types";

import Player2ShadowDom from "../../components/PlayerShadowDom/Player2ShadowDom";
import ActivityDetails from "../../components/ActivityDetails/ActivityDetails";

export const Player2 = (props) => {
  const _activityTitle =
    props.launchAs === "newActivity" ? "New activity" : props.activity.title;
  const _activityId = props.activity?.id ?? props.activityId;
  const _channelId = props.activity?.channel ?? props.channelId;
  const _channelName = props.activity?.channel_name ?? props.channelName;

  const requiredPlayerOptions = {
    activityId: props.activityId,
    channelId: props.channelId,
    canAuthor: props.canAuthor,
  };

  const playerOptionsViewPublished = {
    ...requiredPlayerOptions,
  };
  const playerOptionsViewDraft = {
    ...requiredPlayerOptions,
    startInAuthorMode: true,
  };
  const playerOptionsNewActivity = {
    ...requiredPlayerOptions,
    isNewActivity: true,
    startInAuthorMode: true,
  };

  function getPlayerOptions() {
    if (props.launchAs === "published") {
      return playerOptionsViewPublished;
    } else if (props.launchAs === "draft") {
      return playerOptionsViewDraft;
    } else if (props.launchAs === "newActivity") {
      return playerOptionsNewActivity;
    } else {
      console.warn(
        `[Player2 container] - 'launchAs' property was not recognized.`,
        props.launchAs
      );
      return requiredPlayerOptions;
    }
  }

  return (
    <>
      <Player2ShadowDom size="normal" playerOptions={getPlayerOptions()} />
      <ActivityDetails
        activityDescription={props.activity?.description}
        activityId={_activityId}
        activityImage={props.activity?.image}
        activityTitle={_activityTitle}
        canEditActivity={false} //We don't allow this button to display for player v2 activities
        channelAvatar={props.channelAvatar}
        channelId={_channelId}
        channelName={_channelName}
        history={props.history}
        isViewingFromSubscription={props.isViewingFromSubscription}
        subscriberId={props.subscriberId}
      />
    </>
  );
};

Player2.defaultProps = {
  canAuthor: false,
};
Player2.propTypes = {
  activity: PropTypes.any,
  activityId: PropTypes.string.isRequired,
  canAuthor: PropTypes.bool,
  channelAvatar: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  history: PropTypes.any,
  isViewingFromSubscription: PropTypes.bool,
  subscriberId: PropTypes.string,
  launchAs: PropTypes.oneOf(["published", "draft", "newActivity"]),
};
