import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import style from "./Embed.module.scss";

import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import PlayerShadowDom from "../../components/PlayerShadowDom/PlayerShadowDom";
import PageError from "../../components/Error/PageError";

import { useQuery } from "../../hooks/useQuery";
import { useActivity } from "../../hooks/useActivity";
import { useChannelEmbedPermissions } from "../../hooks/Channel/useChannelEmbedPermissions";
import { useIframe } from "../../hooks/useIframe";
import { CHANNEL_EMBED_TYPES } from "../../constants/channelEmbedTypes";
import { EmbeddedActivityPreview } from "../../components/EmbeddedActivityPreview/EmbeddedActivityPreview";
import { useChannel } from "../../hooks/Channel/useChannel";
import CustomPlayerShadowDom from "../../components/PlayerShadowDom/CustomPlayerShadowDom";
import Player2ShadowDom from "../../components/PlayerShadowDom/Player2ShadowDom";

const Embed = () => {
  // 1.0 Set activityId from the URL match params
  // 1.1 Set channelId from the URL match params if available.
  const { activityId } = useParams();
  let channelId = null;
  // 1.2 set searchParams from the URL search params
  let searchParams = useQuery();
  // 0.0 Set isLoading=true, error=false, showError=false, errorMessage="", permissionError="false", permissionErrorMessage=""

  // eslint-disable-next-line no-unused-vars
  const [generalErrorMessage, setGeneralErrorMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [generalError, setGeneralError] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const [permissionErrorMessage, setPermissionErrorMessage] = useState("");
  const [hasPermission, setHasPermission] = useState(null);
  const [referrerDomain, setReferrerDomain] = useState(null);
  const [autoLoad, setAutoLoad] = useState(false);
  const [startingSlideIndex, setStartingSlideIndex] = useState(null);
  const [running, setRunning] = useState(false);

  // 0.1 Display the page loading indicator
  // This happens in the renderContent() function.

  // 2.0 Get activity data from API using activityId (async)
  // 2.1 If error, set errorMessage=error.message, set error=true, set showError=true, & bail
  // 2.2 If we don't have channelId yet, set it from the activity data
  const { activity, error: activityError } = useActivity(activityId);

  useEffect(() => {
    if (activityError) {
      setGeneralErrorMessage("The requested activity could not be found.");
      setGeneralError(true);
    }
  }, [activityError]);

  // 2.3 Preload activity image using javascript
  // ToDo: preload image

  // 3.0 Set referringDomain from the iFrame
  const { iframe, parentUrl } = useIframe();

  // An effect that takes the referrer from the iFrame and sets referrerDomain
  // Note: The URL API is not supported in IE
  useEffect(() => {
    if (parentUrl) {
      const url = new URL(parentUrl);
      if (url) {
        setReferrerDomain(url.hostname);
      }
    }
  }, [parentUrl]);

  // 4.0 Get channel data from API using channelId
  // This is retreived by the useChannelEmbedPermissions hook if not already in redux.

  // 5.0 Set embed_permissions=channel.list[channelId].embed_permission
  const channelEmbedPermission = useChannelEmbedPermissions(getChannelId());
  const { channel } = useChannel(getChannelId());

  // 6.0 If embed permission is 'disabled', set permissionError=true, set permissionErrorMessage, hasPermission=false, set showError=true, bail
  // 6.1.1 If embed permission is 'white_list, check if referringDomain is included in the white_list
  // 6.1.2 If included in 'white_list', continue
  // 6.1.3 If not included in 'white_list, set error=true, set permissionErrorMessage, set showError=true, bail
  // 6.2 If embed permission is 'anywhere', set hasPermission=true, continue

  /// An effect that checks for iframe and ensures the activity is allowed to be embedded.
  useEffect(() => {
    if (iframe) {
      if (
        channelEmbedPermission.permission &&
        !channelEmbedPermission.fetching &&
        !channelEmbedPermission.error
      ) {
        if (
          channelEmbedPermission.permission === CHANNEL_EMBED_TYPES.DISABLED
        ) {
          setPermissionErrorMessage(
            "This activity doesn't have permission to be embedded on any site. If you feel this is a mistake, contact the owner of the channel to update permissions."
          );
          setPermissionError(true);
          setHasPermission(false);
        } else if (
          channelEmbedPermission.permission ===
          CHANNEL_EMBED_TYPES.WHITE_LIST &&
          referrerDomain
        ) {
          if (
            channelEmbedPermission.domainsAllowed.includes(referrerDomain, 0)
          ) {
            setPermissionError(false);
            setPermissionErrorMessage("");
            setHasPermission(true);
          } else {
            setPermissionErrorMessage(
              `This activity doesn't have permission to be embedded on this site (${referrerDomain}). If you feel this is a mistake, contact the owner of the channel to update permissions.`
            );
            setPermissionError(true);
            setHasPermission(false);
          }
        } else if (
          channelEmbedPermission.permission === CHANNEL_EMBED_TYPES.ANYWHERE
        ) {
          setPermissionError(false);
          setPermissionErrorMessage("");
          setHasPermission(true);
        }
      } else if (channelEmbedPermission.error) {
        setGeneralErrorMessage("Unable to retreive channel embed permissions");
        setGeneralError(true);
      }
      // Handle embed permission when page is not embeded
    } else if (channelEmbedPermission.permission) {
      if (channelEmbedPermission.permission === CHANNEL_EMBED_TYPES.DISABLED) {
        setPermissionErrorMessage(
          "This activity doesn't have permission to be embeddd on any site. If you feel this is a mistake, contact the owner of the channel to update permissions."
        );
        setPermissionError(true);
        setHasPermission(false);
      } else if (
        channelEmbedPermission.permission === CHANNEL_EMBED_TYPES.WHITE_LIST
      ) {
        setPermissionErrorMessage(
          "This activity doesn't have permission to be embeddd on this site. Please ensure sure you are viewing this page inside an iFrame on a site you have whitelisted. If you feel this is a mistake, contact the owner of the channel to update permissions."
        );
        setPermissionError(true);
        setHasPermission(false);
      } else if (
        channelEmbedPermission.permission === CHANNEL_EMBED_TYPES.ANYWHERE
      ) {
        setPermissionError(false);
        setPermissionErrorMessage("");
        setHasPermission(true);
      }
    }
  }, [
    channelEmbedPermission,
    channelEmbedPermission.permission,
    channelEmbedPermission.fetching,
    channelEmbedPermission.error,
    channelEmbedPermission.domainsAllowed,
    iframe,
    referrerDomain,
  ]);

  // 7.0 Apply any URL Search paramaters to page and webgl player
  useEffect(() => {
    if (searchParams?.toString()) {
      // Handle autoload. If value = 1, then the activity should autoload.
      const autoLoadParam = searchParams.get("autoload"); // get param
      const autoLoadParamInt = parseInt(autoLoadParam); // convert to Int
      if (autoLoadParamInt === 1) {
        setAutoLoad(true);
      }

      // Handle starting slide
      const startingSlideParam = searchParams.get("slideIndex"); // get param
      const startingSlideParamInt = parseInt(startingSlideParam); // convert to Int
      if (!isNaN(startingSlideParamInt)) {
        setStartingSlideIndex(startingSlideParamInt);
      }
    }
  }, [searchParams]);

  // 8.0 Preload webgl player CSS stylesheet
  // ToDO: preload the webgl player stylesheet here

  // 8.0 Create activity options object

  // 9.0 Create the player

  // 10.0 Logic to render all the content.
  const renderContent = () => {

    if (permissionError) {
      return renderPermissionError();
    } else if (generalError) {
      return renderGeneralError();
    } else if (hasPermission) {
      if (autoLoad || running) {
        return renderActivity();
      } else {
        return (
          <EmbeddedActivityPreview
            title={activity.title}
            image={activity.image}
            description={activity.description}
            channelName={activity.channel_name}
            channelImage={channel?.image_url}
            onLoadActivity={() => setRunning(true)}
          />
        );
        // return <RenderActivityPreview />;
      }
    } else {
      return renderLoading();
    }
  };

  const renderPermissionError = () => {
    return (
      <PageError
        error={permissionError}
        title="Permission error"
        errorMessage={permissionErrorMessage}
      />
    );
  };
  const renderGeneralError = () => {
    return (
      <PageError error={generalError} errorMessage={generalErrorMessage} />
    );
  };

  const onPlayerCreated = (player) => {
    const activityOptions = {
      title: activity.title ? activity.title : "",
      canEdit: false,
    };
    if (startingSlideIndex) {
      activityOptions.startOnSlide = startingSlideIndex;
    }

    player.LoadActivityFromJSONString(activity.data, activityOptions);
  };

  const renderActivity = () => {
    if (
      activity.data.includes("app_id")
    ) {
      return (
        <CustomPlayerShadowDom
          size="embed"
          playerName={activity.app_mount_name}
          playerAssets={activity.app_files}
          shadowDom={true}
        />
      );
    }
    else if (activity?.isPlayerV2) {
      return (
        <Player2ShadowDom
          size="embed"
          playerOptions={{
            activityData: activity?.data,
            activityTitle: activity?.title,
            activityDescription: activity?.description,
            channelId: getChannelId()
          }}
        />
      );
    }
    else {
      return (
        <div>
          <PlayerShadowDom
            onPlayerCreated={(player) => onPlayerCreated(player)}
            // playerOptions={{showTitleBar: false}}
            size="embed"
          />
        </div>
      );
    }
  };

  const renderLoading = () => {
    return (
      <div className={style.loadingContainer}>
        <PageLoadingIndicator />
      </div>
    );
  };

  /**
   * Gets the channelId from the best sources available to this script.
   */
  function getChannelId() {
    if (!channelId) {
      if (activity && activity.channel) {
        return activity.channel;
      } else return null;
    } else return channelId;
  }

  return renderContent();
};

export default Embed;
